<template>
  <div class="card card-flush mt-4">
    <div class="card-header">
      <h3 class="card-title">{{ `${$t("Level")} ${level}` }}</h3>
      <div class="card-toolbar">
        <button
          v-if="!isEdit"
          type="button"
          class="btn btn-sm btn-light"
          @click="editChip"
        >
          <i class="fas fa-edit me-2"></i>{{ $t("Edit") }}
        </button>
        <span v-else>
          <button
            type="button"
            class="btn btn-sm btn-light"
            @click="updateChip"
          >
            <i class="fas fa-save me-2"></i>{{ $t("Save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            @click="isEdit = false"
          >
            <i class="fas fa-window-close me-2"></i>{{ $t("Cancel") }}
          </button>
        </span>
      </div>
    </div>
    <div class="card-body">
      <Datatable :table-data="tableData" :table-header="tableHeader">
        <!-- Chip Image -->
        <template v-slot:cell-chip="{ row: data }">
          <img
            :src="`/media/${data.chip}`"
            style="max-height: 50px; max-width: 50px"
          />
        </template>
        <!-- Chip Amount -->
        <template v-slot:cell-chipamount="{ row: data }">
          <label v-if="!isEdit"> {{ $n(data.amount, "currency") }}</label>
          <input
            v-else
            type="number"
            v-model="chipAmt[data.idx]"
            class="form-control form-control-solid w-150px"
            placeholder="1000"
          />
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { defineComponent, watch, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "bet-chips",
  components: {
    Datatable,
  },
  emits: ["refresh"],
  props: {
    level: {
      type: Number,
      required: true,
    },
    chips: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const tableHeader = ref([
      {
        name: t("BetChip.Chip"),
        key: "chip",
        customslot: true,
      },
      {
        name: t("BetChip.Amount"),
        key: "chipamount",
        customslot: true,
      },
    ]);
    /**
     * Update Chip
     *
     */
    const isEdit = ref(false);
    const chipAmt = ref([1000, 1000, 1000, 1000, 1000, 0]);
    const tableData = ref(props.chips);
    tableData.value.forEach((chip, i) => (chipAmt.value[i] = chip.amount));
    /**
     * Set Amount
     *
     */
    watch(
      () => props.chips,
      (newVal) => {
        if (newVal) {
          tableData.value.splice(0, tableData.value.length, ...props.chips);
          tableData.value.forEach(
            (chip, i) => (chipAmt.value[i] = chip.amount)
          );
        }
      }
    );
    const editChip = () => {
      isEdit.value = true;
    };

    const updateChip = async () => {
      isEdit.value = false;

      await ApiService.post("/game/settings/bet-chips", {
        level: props.level,
        betchips: {
          chipamt_1: chipAmt.value[0],
          chipamt_2: chipAmt.value[1],
          chipamt_3: chipAmt.value[2],
          chipamt_4: chipAmt.value[3],
          chipamt_5: chipAmt.value[4],
          chipamt_6: chipAmt.value[5],
        },
      })
        .then(() => {
          Swal.fire(t("BetChip.Chip"), t("BetChip.Update"), "success");
          emit("refresh");
        })
        .catch(() => {
          Swal.fire(t("BetChip.Chip"), t("TryAgain"), "error");
        });
    };

    return {
      tableHeader,
      tableData,
      isEdit,
      chipAmt,
      editChip,
      updateChip,
    };
  },
});
</script>
