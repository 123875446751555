<template>
  <div class="row">
    <div
      class="col-12 col-xxl-3 col-xl-3 col-lg-6 col-md-6"
      v-for="chip in chips"
      :key="chip.id"
    >
      <BetChip :level="chip.level" :chips="chip.chips" @refresh="getChips" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onBeforeMount, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n/index";
import BetChip from "@/components/settings/BetChip.vue";

export default defineComponent({
  name: "game-chip-setting",
  components: {
    BetChip,
  },
  setup() {
    const { t } = useI18n();
    const chips = ref([
      {
        id: null,
        level: 1,
        chips: [
          { chip: "/chip/1-min.png", amount: 0, idx: 0 },
          { chip: "/chip/2-min.png", amount: 0, idx: 1 },
          { chip: "/chip/3-min.png", amount: 0, idx: 2 },
          { chip: "/chip/4-min.png", amount: 0, idx: 3 },
          { chip: "/chip/5-min.png", amount: 0, idx: 4 },
          { chip: "/chip/6-min.png", amount: 0, idx: 5 },
        ],
      },
      {
        id: null,
        level: 2,
        chips: [
          { chip: "/chip/1-min.png", amount: 0, idx: 0 },
          { chip: "/chip/2-min.png", amount: 0, idx: 1 },
          { chip: "/chip/3-min.png", amount: 0, idx: 2 },
          { chip: "/chip/4-min.png", amount: 0, idx: 3 },
          { chip: "/chip/5-min.png", amount: 0, idx: 4 },
          { chip: "/chip/6-min.png", amount: 0, idx: 5 },
        ],
      },
      {
        id: null,
        level: 3,
        chips: [
          { chip: "/chip/1-min.png", amount: 0, idx: 0 },
          { chip: "/chip/2-min.png", amount: 0, idx: 1 },
          { chip: "/chip/3-min.png", amount: 0, idx: 2 },
          { chip: "/chip/4-min.png", amount: 0, idx: 3 },
          { chip: "/chip/5-min.png", amount: 0, idx: 4 },
          { chip: "/chip/6-min.png", amount: 0, idx: 5 },
        ],
      },
      {
        id: null,
        level: 4,
        chips: [
          { chip: "/chip/1-min.png", amount: 0, idx: 0 },
          { chip: "/chip/2-min.png", amount: 0, idx: 1 },
          { chip: "/chip/3-min.png", amount: 0, idx: 2 },
          { chip: "/chip/4-min.png", amount: 0, idx: 3 },
          { chip: "/chip/5-min.png", amount: 0, idx: 4 },
          { chip: "/chip/6-min.png", amount: 0, idx: 5 },
        ],
      },
      {
        id: null,
        level: 5,
        chips: [
          { chip: "/chip/1-min.png", amount: 0, idx: 0 },
          { chip: "/chip/2-min.png", amount: 0, idx: 1 },
          { chip: "/chip/3-min.png", amount: 0, idx: 2 },
          { chip: "/chip/4-min.png", amount: 0, idx: 3 },
          { chip: "/chip/5-min.png", amount: 0, idx: 4 },
          { chip: "/chip/6-min.png", amount: 0, idx: 5 },
        ],
      },
    ]);

    const getChips = async () => {
      const betChips = await ApiService.get("/game/settings/bet-chips").then(
        (res) => res.data
      );
      betChips.forEach((chp) => {
        const chipIdx = chips.value.findIndex((e) => e.level === chp.level);

        chips.value[chipIdx].id = chp.id;
        chips.value[chipIdx].chips = [
          {
            chip: "/chip/1-min.png",
            amount: chp.chipamt_1,
            idx: 0,
          },
          {
            chip: "/chip/2-min.png",
            amount: chp.chipamt_2,
            idx: 1,
          },
          {
            chip: "/chip/3-min.png",
            amount: chp.chipamt_3,
            idx: 2,
          },
          {
            chip: "/chip/4-min.png",
            amount: chp.chipamt_4,
            idx: 3,
          },
          {
            chip: "/chip/5-min.png",
            amount: chp.chipamt_5,
            idx: 4,
          },
          {
            chip: "/chip/6-min.png",
            amount: chp.chipamt_6,
            idx: 5,
          },
        ];
      });
    };
    onBeforeMount(() => getChips());
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.GameChipSetting"), []);
    });
    return {
      chips,
      getChips,
    };
  },
});
</script>
